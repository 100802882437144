import { AxiosError } from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { IStatus } from "@packages/common/src/custom-hooks/types";
import GenericResponseHandler from "@packages/common/src/components/others/GenericResponseHandler";
import DPhiTestimonialListItem from "./DPhiTestimonialListItem";

const DPhiTestimonialsResponseHandler: React.FC<{
  status: IStatus;
  error: AxiosError;
  testimonials: NSWallOfLove.IDPhiTestimonialsResponse;
}> = ({ status, error, testimonials }) => {
  return (
    <GenericResponseHandler status={status} error={error}>
      {!!testimonials && (
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          columnGap="32px"
          rowGap={{ xs: "16px", sm: "32px" }}
          pt={12}
        >
          {testimonials.results.map((testimonial, idx) => (
            <DPhiTestimonialListItem
              key={testimonial.id}
              testimonial={testimonial}
              idx={idx}
            />
          ))}

          {testimonials.results.length === 0 && (
            <Typography>Search results not found</Typography>
          )}
        </Box>
      )}
    </GenericResponseHandler>
  );
};

export default DPhiTestimonialsResponseHandler;
