import Modal, { ModalProps } from "@mui/material/Modal";
import Box, { BoxProps } from "@mui/material/Box";

const ModalWithStyles: React.FC<{
  modalProps: Omit<ModalProps, "children">;
  boxProps?: Omit<BoxProps, "position" | "top" | "left" | "transform">;
  children: React.ReactNode;
}> = ({ modalProps, boxProps, children }) => {
  return (
    <Modal {...modalProps}>
      <Box
        {...(boxProps || {})}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          ...(boxProps?.sx || {}),
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default ModalWithStyles;
