import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import HighlightOff from "@mui/icons-material/HighlightOff";
import { useMediaQuery, useTheme } from "@mui/material";

import ModalWithStyles from "@packages/common/src/components/modal/ModalWithStyles";
import TestimonialListItem from "./TestimonialListItem";

const DPhiTestimonialListItem: React.FC<{
  testimonial: NSWallOfLove.IDPhiTestimonialItem;
  idx: number;
}> = ({ testimonial, idx }) => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const [modal, setModal] = useState(false);

  return (
    <>
      <TestimonialListItem idx={idx} testimonial={testimonial}>
        <Typography
          component="span"
          sx={{
            fontWeight: 600,
            cursor: "pointer",
            color: theme.palette.info.main,
          }}
          onClick={() => setModal(true)}
        >
          Read More
        </Typography>
      </TestimonialListItem>
      <ModalWithStyles
        modalProps={{
          open: modal,
          onClose: () => setModal(false),
          sx: {
            display: "grid",
            placeItems: "center",
            height: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          },
        }}
        boxProps={{
          sx: {
            p: 0,
            outline: "none",
            position: "static",
            transform: "none",
          },
        }}
      >
        <TestimonialListItem
          idx={idx}
          useWidth={isDownMd ? false : true}
          testimonial={testimonial}
          closeEl={
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={() => setModal(false)} size="small">
                <HighlightOff />
              </IconButton>
            </Box>
          }
        />
      </ModalWithStyles>
    </>
  );
};

export default DPhiTestimonialListItem;
