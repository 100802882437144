import Image from "next/image";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import MuiLink from "@mui/material/Link";
import { useTheme } from "@mui/material";

import RichTextRenderer from "../../../common/components/rich-text-renderer/RichTextRenderer";
import { LinkedInIcon } from "../../utils/icons";

const bgColors: { [key: number]: string } = {
  0: "#EEFFEB",
  1: "#E7F9FF",
  2: "#FFF9DC",
  3: "#FFF4FD",
  4: "#FFF5F0",
  5: "#EDF1FF",
};

const TestimonialListItem: React.FC<{
  testimonial: NSWallOfLove.IDPhiTestimonialItem;
  idx: number;
  children?: React.ReactNode;
  closeEl?: React.ReactNode;
  useWidth?: boolean;
}> = ({ testimonial, idx, children, closeEl, useWidth }) => {
  const theme = useTheme();

  return (
    <Box
      bgcolor={bgColors[idx % 6]}
      borderRadius="12px"
      p={4}
      width={useWidth ? "600px" : ""}
      boxSizing="content-box"
      sx={
        useWidth
          ? undefined
          : {
              width: 300,
              [theme.breakpoints.down("md")]: {
                width: 280,
              },
            }
      }
    >
      {closeEl}
      <Box>
        {children && testimonial.testimonial.length > 350 ? (
          <Box>
            <RichTextRenderer
              richTextHtml={`<p>${testimonial.testimonial.slice(
                0,
                350
              )}...</p>`}
            />
            {children}
          </Box>
        ) : (
          <RichTextRenderer
            richTextHtml={`<p>${testimonial.testimonial}</p>`}
          />
        )}
      </Box>
      <Box pt={4} pb={2}>
        <Avatar
          sx={{
            "& img": {
              width: "100%",
              objectFit: "cover",
            },
          }}
        >
          <Image
            src={testimonial.author_image_url}
            alt={testimonial.author_name}
            width="100%"
            height="100%"
          />
        </Avatar>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="end">
        <Box
          sx={{
            "& .MuiTypography-h5": {
              fontWeight: 700,
            },
          }}
        >
          <Typography variant="h5">{testimonial.author_name}</Typography>
          <Typography>{testimonial.author_designation}</Typography>
        </Box>
        {testimonial.linkedin_url && (
          <Box>
            <MuiLink href={testimonial.linkedin_url} target="_blank">
              <LinkedInIcon />
            </MuiLink>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TestimonialListItem;
