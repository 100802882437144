import { useEffect } from "react";
import Link from "next/link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { TypographyProps } from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@mui/material";

import useAsync from "@packages/common/src/custom-hooks/useAsync";
import Section from "../../../common/components/layout/utils/Section";
import Heading from "../common/Heading";
import DPhiTestimonialsResponseHandler from "./DPhiTestimonialsResponseHandler";

const DPhiTestimonials: React.FC<{
  headingProps?: TypographyProps;
  showEmoji?: boolean;
}> = ({ headingProps, showEmoji = false }) => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const {
    run,
    data: testimonials,
    error,
    status,
  }: IUseAsyncReturnProps<NSWallOfLove.IDPhiTestimonialsResponse> = useAsync();

  useEffect(() => {
    const fetchTestimonials = async () => {
      const wallOfLoveService = new (
        await import("../../services/wallOfLoveService")
      ).default();

      run(
        wallOfLoveService.getDPhiTestimonials({
          page_size: isDownMd ? 4 : 6,
        })
      );
    };

    fetchTestimonials();
  }, [isDownMd, run]);

  return (
    <Section boxProps={{ py: { xs: 12, sm: 18 } }}>
      <Box
        sx={{
          color: "neutral.dark",
        }}
      >
        <Heading typographyProps={headingProps}>
          The community continues to inspire and love {showEmoji && "❤️"}
        </Heading>
        <DPhiTestimonialsResponseHandler
          status={status}
          error={error}
          testimonials={testimonials}
        />
        <Box display="flex" justifyContent="center" pt={12}>
          <Link href="/wall-of-love/testimonials" passHref>
            <Button
              endIcon={<ArrowForward />}
              sx={{
                color: "text.primary",
                letterSpacing: "normal",
              }}
            >
              View All
            </Button>
          </Link>
        </Box>
      </Box>
    </Section>
  );
};

export default DPhiTestimonials;
